import Head from 'next/head'

const MetaTags = ({ title, description }: any) => (
  <Head>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta name="author" content="Tobias Ackermann" />

    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
  </Head>
)

export default MetaTags