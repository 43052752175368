import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    'content': {
      'flexGrow': 1,
      //'background-color': '#fafafa',
      'padding': theme.spacing(2),
      [theme.breakpoints.down(600)]: {
        'padding': 0,
      },
    },
    'toolbar': theme.mixins.toolbar,
  }),
);

export default function MyMainContent(props: any) {
  const classes = useStyles();
  return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}      
      </main>
  );
}
