import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    'indexOuterGrid': {
      width: 'calc(100%)',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 900,
      padding: theme.spacing(0),
    }
  }),
);

export default function IndexOuterGrid(props: any) {
  const classes = useStyles();
  return (
      <Grid container spacing={2} className={classes.indexOuterGrid}>
        {props.children}      
      </Grid>
  );
}
