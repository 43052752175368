import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    'root': {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      display: 'flex',
      flexGrow: 1,
    },
  }),
);

export default function MyRoot(props: any) {
  const classes = useStyles();
  return (
      <div className={classes.root}>
        {props.children}      
      </div>
  );
}
